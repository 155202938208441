import { Grid } from '@mui/material';
// import BigToutButton from '../../Components/BigToutButton';
import BasePageTemplate from '../BasePageTemplate';
// import LogoStrip from './logoStrip';
import styles from './styles.module.scss';
import TeaserVid from '../../Components/TeaserVid';
import PasswordGate from '../../Components/Gates/PasswordGate';
import InvestorKit from '../../Components/InvestorKit';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
const InvestorPage=({})=>{
  const {subpage} = useParams()
  useEffect(()=>{
    console.log('~IP subpage',subpage)
  },[subpage])
  return (
    <>
      <BasePageTemplate>
        <PasswordGate>
          <Grid container spacing={8}>
            <Grid item xs={12}>
              <InvestorKit />
            </Grid>
          </Grid>
        </PasswordGate>
        <TeaserVid />
      </BasePageTemplate>
    </>
  )
}

export default InvestorPage