import { Box, Modal } from '@mui/material';
import styles from './styles.module.scss';

const GoodyModal=({content})=>{
  return (
    <Modal
      open={true}
      onClose={()=>{}}
    >
      <Box className={styles.GoodyModal}>
        {content}
      </Box>
    </Modal>
  )
}

export default GoodyModal