import { Box } from '@mui/material';
import HeaderNav from '../../Components/HeaderNav';
import styles from './styles.module.scss';
import MobileNav from '../../Components/MobileNav';
import useGlobalState from '../../Stores/GlobalStateManager';
import TeaserVid from '../../Components/TeaserVid';
import UserNavDrawer from '../../Components/UserNavDrawer';
import { useState } from 'react';

const BasePageTemplate=({children,showTeaserVid=true})=>{
  const {mobileNavIsOpen} = useGlobalState(state=>({mobileNavIsOpen:state.mobileNavIsOpen}))
  const [navDrawerOpen,setNavDrawerOpen] = useState(false);
  window.setNavDrawerOpen = setNavDrawerOpen; //TODO DELME
  return (
    <Box className={mobileNavIsOpen? styles.BasePageTemplateScrollLocked:styles.BasePageTemplate}>
      <HeaderNav />
      <MobileNav />
      <Box className={styles.contentWrapper}>
        <div>
          {children}
        </div>
        {!!navDrawerOpen && <UserNavDrawer />}
      </Box>
      
    </Box>
  )
}

export default BasePageTemplate