import { experimental_extendTheme as extendTheme } from '@mui/material/styles'

const breakpoints = {
  xs: 0,
  sm: 600,
  md: 900,
  lg: 1250,
  xl: 1536,
  hd: 1920,
  ultrawide: 3440
}

const theme = extendTheme({
  shape: {
    headerNav: {
      height: {
        L: '70px'
      },
      zIndex: '9998'
    },
    subHeaderNav: {
      height: {
        L: '65px'
      },
      zIndex: '9999'
    },
    mobileNav: {
      height: '70px',
      zIndex: '9999',
      itemHeight: '70px',
      subItemHeight: '60px',
    },
    basePageTemplate: {
      baseContentZIndex: 2,
      userNavDrawerWidth: {
        ultrawide: '500px',
        hd: '500px',
        xl: '500px',
        lg: '500px',
        md: '0'
      }
    }
  },
  typography: {
    fontFamily: '"input-mono", monospace',
  },
  palette: {
    UltraPlush: {
      cyan: "#00aeef",
      magenta: "#ec008c"
    },
    Obscura: {
      Void: "#000",
      Meaning: "#fff",
      TwilightEnigma: '#8E3CCC',
      SapphireSkies: '#21A3B9',
      SecretReef: '#35FBEE',
      VerdantMystery: '#87C830',
      CoralHorizon: '#FB59A2',
      ParadisePapaya: '#FE7450',
      Lava: '#FF6F02',
      SunsetEmber: '#FD2E24',
      JungleCitrine: "#FDB834",
      RadiantStolenGold: '#FFD801',

    },
    mobileNavBodyBG: '#0d0d0d',
    mobileNavItemBG: '#1a1a1a',
    mobileNavSubItemBG: '#050505',
    userNavDrawerBG: '#080808'
  },
  values: breakpoints,
})

// fonts
theme.typography.headerNav = {
  fontWeight: 400,
  color: "#fff",
  textDecoration: 'none',
  fontSize: '1.5rem',
  [theme.breakpoints.down('md')]: {
    fontSize: '1.5rem'
  }
}
theme.typography.subNav = {
  fontWeight: 400,
  color: "#fff",
  textDecoration: 'none',
  fontSize: '1rem'
}
theme.typography.bigToutHeader = {
  fontWeight: 400,
  color: "#fff",
  textDecoration: 'none',
  fontSize: '2.125rem',
}
theme.typography.bigToutSubHeader = {
  fontWeight: 400,
  color: "#fff",
  textDecoration: 'none',
  fontSize: '1.25rem'
}
theme.typography.investor_kit = {
  fontWeight: 400,
  color: "#fff",
  textDecoration: 'none',
  fontSize: '2.5rem',
  letterSpacing: 3,
  [theme.breakpoints.down('md')]: {
    fontSize: '2rem'
  }
}
theme.typography.investor_panel_title ={
  fontWeight: 400,
  textDecoration: 'none',
  fontSize: '2.375',
  marginTop: '0.3em',
  textAlign: 'center',
  [theme.breakpoints.down('md')]: {
    fontSize: '1.7rem'
  }
}

theme.typography.mobileNavItem ={
  fontWeight: 400,
  textDecoration: 'none',
  color: theme.colorSchemes.light.palette.Obscura.Meaning,
  fontSize: '2.375rem',
  marginTop: '0.3em',
  textAlign: 'center',
  marginRight: '25px',
  [theme.breakpoints.down('md')]: {
    fontSize: '1.7rem'
  }
}

theme.typography.mobileSubNavItem ={
  fontWeight: 400,
  textDecoration: 'none',
  color: theme.colorSchemes.light.palette.Obscura.Meaning,
  fontSize: '1.35rem',
}

theme.typography.GreebleModalHeader = {
  fontSize: "24px"
}

theme.typography.GreebleModalRSide = {
  rotate: "-90deg",
  position: "absolute",
  top: "133%",
  fontWeight: 400,
  fontSize: "21px"
}


theme.typography.greebleModalActionButton = {
  fontWeight: 400,
  textDecoration: 'none',
  color: theme.colorSchemes.light.palette.Obscura.Meaning,
  fontSize: '26px',
  fontFamily: '"input-mono", monospace',
  textShadow: "-6px 0px 10px rgba(236, 0, 140, 1), 0px 9px 20px rgba(236, 0, 140, 0.77)"
}

theme.typography.logoutPage = {
  fontSize: "3rem",
  color: "#fff",
  fontWeight: 200,
  background: "#000",
  padding: "0.4em",
  maxWidth: "80%"
}

theme.typography.userNavDrawerNotificationAlert = {
  color: theme.colorSchemes.light.palette.Obscura.SapphireSkies,
  fontSize: '1.5em'
}

theme.typography.userNavDrawerItem = {
  color: theme.colorSchemes.light.palette.Obscura.Meaning,
  fontSize: '2em'
}

theme.typography.userNavDrawerItemTwoLine = {
  color: theme.colorSchemes.light.palette.Obscura.Meaning,
  fontSize: '1.5em'
}

theme.typography.userNavPadItem = {
  color: theme.colorSchemes.light.palette.Obscura.Meaning,
  fontSize: '1.2em',
  textAlign: 'center',
  // background: '#000',
  padding: '0.2em 0.5em',
  borderRadius: '10px',
  alignSelf: 'center'
}

theme.typography.userNavPadItemTwoLine = {
  color: theme.colorSchemes.light.palette.Obscura.Meaning,
  fontSize: '1em',
  textAlign: 'center',
  // background: '#000',
  padding: '0.2em 0.2em',
  borderRadius: '10px',
  alignSelf: 'center'
}
theme.typography.foundRabbitHeader = {
  color:"#fff"
}
theme.typography.foundRabbitName = {
  color:"#fff",
  fontSize: '50px',
  textAlign: 'center',
  maxWidth: '400px'
}
theme.typography.foundRabbitDescription = {
  color:"#fff",
  marginBottom: '20px',
  maxWidth: '400px'
}

export default theme;