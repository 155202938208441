import { Box, Button, TextField, Typography } from '@mui/material';
import BasePageTemplate from '../BasePageTemplate';
import styles from './styles.module.scss';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import GreebleModal from '../../Components/GreebleModal';
import { MuiTelInput } from 'mui-tel-input';

import useAuthState from '../../Stores/AuthStateManager';
import TeaserVid from '../../Components/TeaserVid';
const SignupPage=({})=>{
  const usernameRef=useRef();
  // const phoneNoRef=useRef();
  const [phoneNo,setPhoneNo] = useState('');
  const navTo = useNavigate();

  const {doSignUp} = useAuthState((state)=>({doSignUp:state.doSignUp}));
 
  const onPhoneNoChange = useCallback((newVal)=>{
    console.log('setting phone no to ', newVal)
    setPhoneNo(newVal);
  },[])

  const _doSignUp = useCallback(async ()=>{
    if (!doSignUp) {console.log('!CAU no doSignUp'); return;}
    const signupResult = await doSignUp(phoneNo,usernameRef.current.querySelector('input').value)
    if (signupResult) {navTo('/verifyAuth')}
  },[phoneNo,doSignUp])

  const InputFields = useMemo(()=>{
    return <Box className={styles.fieldsWrapper}>
      <MuiTelInput name="phonenumber" defaultCountry='US' label="Phone Number" value={phoneNo} onChange={onPhoneNoChange} />
      <TextField name="username" ref={usernameRef} label="Select a Username" sx={{'label':{zIndex:2}}}/>
    </Box>
  },[phoneNo,onPhoneNoChange])

  return (
    <>
      <BasePageTemplate>
        <GreebleModal mainBodyContent={InputFields} actionButtonTitles={["Sign Up"]} actionButtonActions={[_doSignUp]} postActionButtonContent={<p>Already have an account? <br/> <a onClick={()=>{navTo('/login')}}>Login here!</a></p>} />
        {/* <TeaserVid /> */}
      </BasePageTemplate>
    </>
  )
}

export default SignupPage