import { useEffect } from "react"
import useGlobalState from "../../Stores/GlobalStateManager"
import HomePage from "../HomePage"

const AddiesPage=({})=>{
  const {findRabbit} = useGlobalState((state)=>({findRabbit:state.findRabbit}))
  useEffect(()=>{
    findRabbit("Addie")
  },[])
  return (
    <>
      <HomePage />
    </>
  )
}

export default AddiesPage