import { Auth } from 'aws-amplify';
import {create} from 'zustand';
import { subscribeWithSelector, persist, createJSONStorage } from 'zustand/middleware'
import {v4 as uuidV4} from 'uuid'
const useAuthState = create(
  persist(subscribeWithSelector((set,get)=>({
    partiallyAuthenticatedUserObject: false,
    setPartiallyAuthenticatedUserObject: (newPartiallyAuthenticatedUserObject)=>{
      set({partiallyAuthenticatedUserObject:newPartiallyAuthenticatedUserObject})
    },
    currentAuthenticatedUser: null,
    // setCurrentAuthenticatedUser: (newAuthedUser)=>{
    //   console.log('!CAU2 setCurrentAuthenticatedUser newAuthedUser',newAuthedUser);
    //   set({currentAuthenticatedUser:newAuthedUser})
    // },
    doSignUp: async (phoneNo,desiredUsername)=>{
      try { 
        const cleanPhoneNo = phoneNo.replace(/\s/g, '');
        // const desiredUsername = usernameRef.current.querySelector('input').value
        console.log('!! doSignup. username:',desiredUsername,"phone number",cleanPhoneNo)
        await Auth.signUp({
          username: cleanPhoneNo,
          password: uuidV4(),
          attributes:{
            phone_number: cleanPhoneNo,
            preferred_username: desiredUsername
          }
        })
        const signInResult = await Auth.signIn(cleanPhoneNo);
        console.log('!! signInResult',signInResult);
        // setPartiallyAuthenticatedUserObject(signInResult);
        set({partiallyAuthenticatedUserObject:signInResult})
        return true;
      } catch (error) {
        console.log('error signing up:', error);
        return false;
      }
    },
    verifyAuth: async (enteredVerificationCode)=>{
      const _partiallyAuthenticatedUserObject = get().partiallyAuthenticatedUserObject;
      console.log('!CAU AuthStateManager verifyAuth enteredVerificationCode',enteredVerificationCode,'_partiallyAuthenticatedUserObject',_partiallyAuthenticatedUserObject);
      try {      
        // const enteredVerificationCode = verificationCodeRef.current.querySelector('input').value;
        console.log('!!verifyAuth enteredVerificationCode',enteredVerificationCode,'_partiallyAuthenticatedUserObject',_partiallyAuthenticatedUserObject);
        const authVerification = await Auth.sendCustomChallengeAnswer(_partiallyAuthenticatedUserObject,enteredVerificationCode);
        console.log('!! authVerification:',authVerification)
        const currentAuthenticatedUser = await Auth.currentAuthenticatedUser();
        console.log('!! currentAuthenticatdUser',currentAuthenticatedUser)
        set({currentAuthenticatedUser:currentAuthenticatedUser})
        return true;
        // navTo('/');
      }
      catch(err){
        console.log('error verifying code:',err)
        return false;
      }
    },
    doLogin: async (cleanPhoneNo)=>{
      try { 
        // const cleanPhoneNo = phoneNo.replace(/\s/g, '');
        const signInResult = await Auth.signIn(cleanPhoneNo);
        console.log('!! signInResult',signInResult);
        // setPartiallyAuthenticatedUserObject(signInResult);
        set({partiallyAuthenticatedUserObject:signInResult})
        return true
      } catch (error) {
        console.log('error signing up:', error);
        return false;
      }
    },
    doLogout: async ()=>{
      console.log('!CAU2 doLogout');
      await Auth.signOut();
      set({currentAuthenticatedUser:null});
      return true;
    }
  })),
  {
    name: 'auth-storage',
    storage: createJSONStorage(()=>sessionStorage)
  }
  )
)

export default useAuthState