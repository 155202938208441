import { Link } from 'react-router-dom';
import styles from './styles.module.scss';
import { Box, Typography } from '@mui/material';
import { useMemo } from 'react';

const BigToutButton=({route=null,func=null,title="Big Tout",subTitle="Needs config", color="var(--mui-palette-UltraPlush-magenta)"})=>{
  const content = useMemo(()=>{
    return (
      <>
        <Typography variant="bigToutHeader" component={"h1"}>{title}</Typography>
        <Typography variant="bigToutSubHeader" component={"h2"}>{subTitle}</Typography>
      </>
    )
  },[title,subTitle])
  if (route){
    return (
      <Link to={route} className={styles.BigToutButton} style={{'--base-color':color}}>
        {content}
      </Link>
    )
  }
  else  {
    return (
      <Box className={styles.BigToutButton} style={{'--base-color':color}}>
        {content}
      </Box>
    )
  }

  
  
}

export default BigToutButton