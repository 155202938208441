import { Box, Button, Typography } from '@mui/material';
import styles from './styles.module.scss';
import closeX from '../../assets/img/CMCloseX.png'
import {ReactComponent as GreebleLine} from '../../assets/img/greebleLine4.svg'
import {ReactComponent as StackedTris} from '../../assets/img/stackedTris.svg'
const GreebleModal=({header="Sign Up for Obscura",closeFunction=null,mainBodyContent=<span>Main body Content</span>,actionButtonTitles=["Sign Up"],actionButtonActions=[],postActionButtonContent=<p>Already have an account? <br/><a href="#">Login</a></p>,secondaryBodyContent="I understand that by signing up for Obscura, I consent to receive One Time Passwords (OTPs) at this number. Messaging and data rates may apply."})=>{
  return (
    <Box className={styles.GreebleModal}>
      <Box className={styles.headerBox} style={{'--headerBoxAnimTime':'500ms'}}>
        <Typography variant="GreebleModalHeader" component="p">{header}</Typography>
        {!!closeFunction && <button className={styles.closeButton} onClick={closeFunction}>
          <img src={closeX} />
        </button>}   
        <span/>
        <span/>
        <span/>
        {!closeFunction && <span/>}
      </Box>
      <Box className={styles.mainBody}>
        {mainBodyContent}
      </Box>
      {secondaryBodyContent && <Box className={styles.secondaryBody}>
        {secondaryBodyContent}
      </Box>}
      <Box className={styles.actionButtons}>
        {actionButtonTitles.map((title,idx)=>{
          return <button className={styles.actionButton} onClick={actionButtonActions[idx] || null}>
            <Typography variant="greebleModalActionButton" component="p">{title}</Typography>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </button>
        })}
        <Box className={styles.postActionButtonContent}>
          {postActionButtonContent}
        </Box>
      </Box>
      <Box className={styles.LSideGreebles}>
        <span/>
        <span/>
        <div>
          <span/>
          <span/>
          <span/>
          <span/>
        </div>
        <GreebleLine />
        <span/>
        <StackedTris />
      </Box>
      <Box className={styles.RSideGreebles}>
        <span/>
        <span/>
        <Typography variant="GreebleModalRSide" component="span">Obscura</Typography>
      </Box>
    </Box>
  )
}

export default GreebleModal