import { Box, Button, TextField, Typography } from '@mui/material';
import BasePageTemplate from '../BasePageTemplate';
import styles from './styles.module.scss';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import GreebleModal from '../../Components/GreebleModal';
import useAuthState from '../../Stores/AuthStateManager';

const VerifyAuthPage=({})=>{
  const verificationCodeRef = useRef();
  const {verifyAuth} = useAuthState((state)=>({verifyAuth:state.verifyAuth}))
  const navTo = useNavigate();
  const _doVerify = useCallback(async ()=>{
    const verificationResult = await verifyAuth(verificationCodeRef.current.querySelector('input').value);
    if (verificationResult) {
      navTo('/')
    }
  },[verifyAuth])

  const InputFields = useMemo(()=>{
    return <Box className={styles.fieldsWrapper}>
      <TextField name="verificationCode" ref={verificationCodeRef} label="Please enter your verification code."/>
    </Box>
  },[_doVerify,verificationCodeRef])



  return (
    <>
      <BasePageTemplate>
        <GreebleModal header="Verify your login" mainBodyContent={InputFields} actionButtonTitles={["Verify"]} actionButtonActions={[_doVerify]} secondaryBodyContent='' postActionButtonContent='' />
      </BasePageTemplate>
    </>
  )
}

export default VerifyAuthPage