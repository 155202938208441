import { Box } from '@mui/material';
import styles from './styles.module.scss';
import LockIcon from '@mui/icons-material/Lock';
import { useCallback, useRef } from 'react';

const PasswordBox=({incorrectPasswordEntered=false,doSubmitPassword=()=>{}})=>{
  const inputTextRef = useRef();
  console.log('pwb incorrectPasswordEntered',incorrectPasswordEntered)
  const onEnterKeyDoSubmit = useCallback((e)=>{
    if (e?.code === "Enter") {
      if(inputTextRef?.current?.value) {
        doSubmitPassword(inputTextRef.current.value)
      }
    }
  },[doSubmitPassword])
  return (
    <>  
      <Box className={incorrectPasswordEntered? styles.IncorrectPasswordBox : styles.PasswordBox}>
        <LockIcon style={{fill:incorrectPasswordEntered? '#f00' : '#fff',marginRight:'20px',fontSize:'2.6rem', cursor:'pointer'}} onClick={()=>{
          if(inputTextRef?.current?.value) {
            doSubmitPassword(inputTextRef.current.value)
          }
        }}/>
        <input ref={inputTextRef} type="text" onKeyUp={onEnterKeyDoSubmit}/>
      </Box>
    </>
  )
}

export default PasswordBox