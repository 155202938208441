import { Box, Button, Typography } from '@mui/material';
import styles from '../styles.module.scss';
import {ReactComponent as DefaultRabbit} from '../../../assets/img/whiteRabbits/noun-rabbit-3336335.svg'
import useGlobalState from '../../../Stores/GlobalStateManager';
const FoundRabbitGoodyTemplate=({header="You Found...",image=<DefaultRabbit sx={{fill:"#fff",'g':{fill:"#fff"},'path':{fill:'#fff'}}}/>,name="Rabbit name not set",description="rabbit description not set",buttonText="Ok",advance=null})=>{
  return (
    <Box className={styles.FoundRabbitGoodyTemplate}>
      <Typography variant="foundRabbitHeader">{header}</Typography>
      {image}
      <Typography variant="foundRabbitName">{name}</Typography>
      <Typography variant="foundRabbitDescription">{description}</Typography>
      <Button variant="outlined" onClick={advance}>{buttonText}</Button>
    </Box>
  )
}

export default FoundRabbitGoodyTemplate