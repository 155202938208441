import useGlobalState from '../../Stores/GlobalStateManager';
import GoodyModal from '../GoodyModal';
import styles from './styles.module.scss';

const ModalResolver=({})=>{
  const {goodyModals, shiftGoodyModals} = useGlobalState((state)=>({goodyModals:state.goodyModals, shiftGoodyModals:state.shiftGoodyModals}))
  console.log('goodyModals[0]',goodyModals[0])
  return (
    <>
      {!!goodyModals?.length && <GoodyModal content={goodyModals[0]} key={goodyModals[0]} />}
    </>
  )
}

export default ModalResolver