import styles from './styles.module.scss';
import {ReactComponent as PitchIcon} from '../../assets/img/noun-pitch-4724363.svg'
import { Box, Typography } from '@mui/material';
const Panel=({color='var(--mui-palette-UltraPlush-cyan)',onClick=()=>{},Icon=PitchIcon, iconScale=1, title="pitch", shadowColor=null})=>{
  return (
    <>
      <Box className={styles.panel} style={{'--base-color':color, '--shadow-color':shadowColor}} onClick={onClick}>
        <Box className={styles.panelIcon} style={{'--icon-scale':iconScale}}>
          <Icon style={{fill:color}}/>
        </Box>
        <Typography variant="investor_panel_title" sx={{color:color}}>{title}</Typography>
      </Box>
    </>
  )
}

export default Panel