import logo from './logo.svg';
import './App.css';
import { Experimental_CssVarsProvider as CssVarsProvider } from '@mui/material/styles'
import { CssBaseline } from '@mui/material';
import { Route, Routes } from 'react-router-dom';
import HeaderNav from './Components/HeaderNav';
import theme from './theme';
import HomePage from './Pages/HomePage';
import InvestorPage from './Pages/InvestorPage'
import LoginPage from './Pages/LoginPage';
import SignupPage from './Pages/SignupPage';
import useGlobalState from './Stores/GlobalStateManager';
import { Auth } from 'aws-amplify';
import { useEffect } from 'react';
import VerifyAuthPage from './Pages/VerifyAuthPage';
import LogoutPage from './Pages/LogoutPage';
import TeaserVid from './Components/TeaserVid';
import ModalResolver from './Components/ModalResolver';
import AddiesPage from './Pages/AddiesPage';




function App() {
  const {user, setUser, showTeaserVid, findRabbit} = useGlobalState((state)=>({user:state.user,setUser:state.setUser, showTeaserVid:state.showTeaserVid,findRabbit:state.findRabbit}))
  window.findRabbit = findRabbit; //TODO DELME

  useEffect(()=>{
    window.WhiteRabbit = ()=>{
      findRabbit("Consuela");
    }
  },[])

  useEffect(()=>{
    const checkAuthenticatedUser = async () => {
      if (!!user) return;
      console.log('checkAuthenticatedUser',Date.now())
      try {
        const _user = await Auth.currentAuthenticatedUser()
        if (!_user) return;
        setUser(_user) 
      }
      catch(err){
        // shh
      }
    }
    checkAuthenticatedUser();
 },[user, setUser])
  return (
    <CssVarsProvider theme={theme}>
      <CssBaseline />
      <Routes>
         {/* Authentication Routes */}
         <Route path="/login" element={<LoginPage />} />
         <Route path="/signup" element={<SignupPage />} />
         <Route path="/verifyAuth" element={<VerifyAuthPage />} />
         <Route path="/logout" element={<LogoutPage />} />
         <Route path="/investor" element={<InvestorPage />}/>
         <Route path="/investor/:subpage" element={<InvestorPage />}/>
         <Route path="/whiteRabbit" element={<AddiesPage />} /> 
         {/* Base Route */}
         <Route path="/" element={<HomePage />} />
      </Routes>
      <ModalResolver />
      {showTeaserVid && <TeaserVid />}
    </CssVarsProvider>
  );
}

export default App;
