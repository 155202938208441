import { Box, Typography } from '@mui/material';
import styles from './styles.module.scss';

import {ReactComponent as NotificationIcon} from '../../assets/img/noun-email-notification-6235193.svg';
import {ReactComponent as ScanIcon} from '../../assets/img/noun-scan-4730275.svg';
import {ReactComponent as PassIcon} from '../../assets/img/noun-pass-2086048.svg';
import {ReactComponent as OrderIcon} from '../../assets/img/noun-food-6228546.svg';
import {ReactComponent as ReceiptIcon} from '../../assets/img/noun-receipt-997787.svg';
import {ReactComponent as ToolkitIcon} from '../../assets/img/noun-caesar-cipher-3085845.svg';
import CopilotsIcon from '../../assets/img/copilotsIcon.png';
import {ReactComponent as ReferIcon} from '../../assets/img/noun-guidance-4728800.svg';
import {ReactComponent as LogoutIcon} from '../../assets/img/noun-exit-2696258.svg';


const UserNavDrawer=({})=>{

  const showNotifications = ()=>{alert(`Look I've shown you a notification`)} // TODO where should this go

  return (
    <Box className={styles.UserNavDrawer} drawerOpen={1? "":false}>
      <ul>
        <li onClick={showNotifications}>
          <NotificationIcon /> 
          <Typography variant="userNavDrawerNotificationAlert">1 new notification</Typography>
        </li>
        <li>
          <ScanIcon />
          <Typography variant="userNavDrawerItem">Scanner</Typography>
        </li>
        <li>
          <PassIcon />
          <Typography variant="userNavDrawerItem">Badge</Typography>
        </li>
        <li>
          <OrderIcon />
          <Typography variant="userNavDrawerItemTwoLine">Orders & Reservations</Typography>
        </li>
        <li>
          <ReceiptIcon />
          <Typography variant="userNavDrawerItemTwoLine">History & Receipts</Typography>
        </li>
        <li>
          <ToolkitIcon />
          <Typography variant="userNavDrawerItem">Toolkit</Typography>
        </li>
        <li>
          <img src={CopilotsIcon} />
          <Typography variant="userNavDrawerItem">Copilots</Typography>
        </li>
        <li>
          <ReferIcon />
          <Typography variant="userNavDrawerItemTwoLine">Refer Friends</Typography>
        </li>
        <li>
          <ScanIcon />
          <Typography variant="userNavDrawerItemTwoLine">Profile Settings</Typography>
        </li>
        <li>
          <LogoutIcon />
          <Typography variant="userNavDrawerItem">Logout</Typography>
        </li>
      </ul>
    </Box>
  )
}

export default UserNavDrawer