import { Box, Button, TextField, Typography } from '@mui/material';
import BasePageTemplate from '../BasePageTemplate';
import styles from './styles.module.scss';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import GreebleModal from '../../Components/GreebleModal';
import useAuthState from '../../Stores/AuthStateManager';
import TeaserVid from '../../Components/TeaserVid';

const LogoutPage=({})=>{
  const navTo = useNavigate();
  const {doLogout} = useAuthState((state)=>({doLogout:state.doLogout}));
  const [logoutState, setLogoutState] = useState("Logging you out...")
  const timeoutRef = useRef(null);
  const shouldAutoNav = useRef(true)
  const initHomeReturn = useCallback(()=>{
    setLogoutState('Logout complete! Returning you home in 3...');
    timeoutRef.current = window.setTimeout(()=>{
      console.log('timeoutRef.current =',timeoutRef.current)
      setLogoutState('Logout complete! Returning you home in 2...');
      timeoutRef.current = window.setTimeout(()=>{
        setLogoutState('Logout complete! Returning you home in 1...')
        timeoutRef.current = window.setTimeout(()=>{
          if (shouldAutoNav.current) navTo('/')
        },1000)
      },1000)
    },1000)
  },[setLogoutState])


  useEffect(()=>{
    console.log('!CAU2 LogoutPage useEffect 1 initHomeReturn',initHomeReturn,'doLogout',doLogout)
    if (!initHomeReturn || !doLogout) return;
    doLogout()
    .then(()=>{
      initHomeReturn();
    })

  },[initHomeReturn,doLogout])

  useEffect(()=>{
    shouldAutoNav.current = true;
    return ()=>{
      console.log('dismount logout page timeoutRef.current',timeoutRef.current)
      shouldAutoNav.current = false;
      window.clearTimeout(timeoutRef.current)
     }
  },[timeoutRef])

  return (
    <>
      <BasePageTemplate>
        <Box>
          <Box className={styles.LogoutBodyWrapper}>
            <Typography variant="logoutPage">{logoutState}</Typography>
          </Box>
          {/* <TeaserVid /> */}
        </Box>
      </BasePageTemplate>
    </>
  )
}

export default LogoutPage