import styles from './styles.module.scss';
import serverPublicKey from "../../../serverPublicKey";
import JSEncrypt from "jsencrypt";
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { SHA256 } from 'crypto-js';
import PasswordBox from './passwordBox';

function str2ab(str) {
  const buf = new ArrayBuffer(str.length);
  const bufView = new Uint8Array(buf);
  for (let i = 0, strLen = str.length; i < strLen; i++) {
    bufView[i] = str.charCodeAt(i);
  }
  return buf;
}

const PasswordGate=({children})=>{
  const jsenc = useMemo(()=>new JSEncrypt(),[]);
  const [passwordPassed,setPasswordPassed] = useState(false);
  const [incorrectPasswordEntered,setIncorrectPasswordEnterered] = useState(false);
  const incorrectPasswordTimerRef = useRef(0);
  jsenc.setPublicKey(serverPublicKey);
  
  const setPasswordCorrect = useCallback(()=>{
   setPasswordPassed(true)   
  },[])

  const setPasswordIncorrect = useCallback(()=>{
      //do shake
      clearTimeout(incorrectPasswordTimerRef.current);
      setIncorrectPasswordEnterered(true);
      incorrectPasswordTimerRef.current = setTimeout(()=>{
        setIncorrectPasswordEnterered(false)
      },1500)
      setPasswordPassed(false)
  },[])

  useEffect(()=>{
    if (localStorage.getItem("investorPassEntered")==="true") {
      setPasswordCorrect();
    }
  },[])
  

  const doSubmitPassword = useCallback(async (pass)=>{
    console.log('doSubmitPassword')
    const encryptedPass = jsenc.encrypt(pass);
    console.log('encryptedPass',encryptedPass)
    fetch('https://7fsq19rdm1.execute-api.us-west-1.amazonaws.com/prod/investorpasscheck', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({p:encryptedPass})
    })
    .then(async (res)=>{
      if (res.status === 200) {
        const signature = await res.text();
        console.log('.then signature',signature)
        if (signature === "ITx996fkGSTnlFFfZl3DhjsxSx/phYXQmKqZI+WCn4HkcMfgWE+qnVgLvjop9226dxQLbCQDwaq+qdvUMKhT9/I3f1d6DtT/ThHgCaRxXlGwBjloBiVWxXe0uoK5Vft70oX6L+KHkh/2Vs8ZrS735RNPJXVTB29ApixOlsPziNM=") {
          setPasswordCorrect();
          localStorage.setItem("investorPassEntered","true")
        }
        else {
          setPasswordIncorrect()
        }
      } 
      else if (res.status === 403) {
        console.log('password incorrect')
        setPasswordIncorrect();
      }
    })


  },[])
  return (
    <>
      {!!passwordPassed && children}
      {!passwordPassed && <PasswordBox incorrectPasswordEntered={incorrectPasswordEntered} doSubmitPassword={doSubmitPassword} />}
    </>
  )
}

export default PasswordGate