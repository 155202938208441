import styles from './styles.module.scss';
import ObscuraLogo23b from '../../assets/img/obscuraLogo23.2.png'
import { Box } from '@mui/material';
const LogoStrip=({sx,additionalComponent})=>{
  return (
    <Box className={styles.LogoStrip} sx={sx}>
      <img src={ObscuraLogo23b} />
      {additionalComponent}
    </Box>
  )
}

export default LogoStrip