import { Grid } from '@mui/material';
import BigToutButton from '../../Components/BigToutButton';
import BasePageTemplate from '../BasePageTemplate';
import LogoStrip from '../../Components/LogoStrip';
import styles from './styles.module.scss';
import TeaserVid from '../../Components/TeaserVid';

const HomePage=({})=>{
  return (
    <>
      <BasePageTemplate>
        <LogoStrip />
        <Grid container spacing={8} className={styles.homeGrid}>
          <Grid item xs={12} md={6}>
            <BigToutButton color="var(--mui-palette-UltraPlush-cyan)" title='Reserve' subTitle='Play or Dining'/>
          </Grid>
          <Grid item xs={12} md={6}>
            <BigToutButton color="var(--mui-palette-UltraPlush-magenta)" title='Explore' subTitle='Discover Obscura'/>
          </Grid>
        </Grid>
        {/* <TeaserVid /> */}
      </BasePageTemplate>
    </>
  )
}

export default HomePage