import styles from './styles.module.scss';
import DonutFlyThrough from '../../assets/vid/DonutFlyThrough2.mp4';
import { Box } from '@mui/material';
import useGlobalState from '../../Stores/GlobalStateManager';
import { useEffect, useRef } from 'react';
const TeaserVid=({})=>{
  const {mobileNavIsOpen} = useGlobalState(state=>({mobileNavIsOpen:state.mobileNavIsOpen}))
  const vidRef = useRef()
  useEffect(()=>{
    console.log('!@ TeaserVid, mobileNavIsOpen:',mobileNavIsOpen)
    if (mobileNavIsOpen) {
      vidRef.current.pause()
    }
    else {
      vidRef.current.play();
    }
  },[mobileNavIsOpen])
  return (
    <Box className={styles.TeaserVid}>
      <Box className={styles.TeaserVidOverlay} />
      <video ref={vidRef} src={DonutFlyThrough} muted={true} loop autoPlay={true}/>
    </Box>
  )
}

export default TeaserVid