import { Box, Typography } from '@mui/material';
import styles from './styles.module.scss';
import CMLogo512 from '../../assets/img/ObscuraCMLogo512.png';
import { Link } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import useAuthState from '../../Stores/AuthStateManager';

const HeaderNav=({})=>{
  const [focusedSubNav,setFocusedSubNav] = useState(null)
  const {currentAuthenticatedUser} = useAuthState((state)=>({currentAuthenticatedUser:state.currentAuthenticatedUser}))
  console.log('!CAU2 headerNav. currentAuthenticatedUser',currentAuthenticatedUser)
  const navTo = useNavigate();
  const itemIsActive = useCallback((itemName)=>{
    //TODO highlight active menu item based on location, use route params
  },[])
  const theme = useTheme();
  const isMobileViewport = useMediaQuery(theme.breakpoints.down('md'));

  const triggerSubLink = useCallback((e)=>{
    console.log('triggersublnk',e.target)
    const dest = e.target.querySelector('a').getAttribute('href')
    console.log('dest',dest)
    navTo(dest);
  },[])

  useEffect(()=>{
    const closeSubNavOnEsc = (e)=>{
      if (e.code==="Escape")
      setFocusedSubNav(null);
    }
    document.addEventListener('keyup',closeSubNavOnEsc)
    return ()=>{
      document.removeEventListener('keyup',closeSubNavOnEsc)
    }
  },[])
  if (isMobileViewport) {
    return null;
  }
  return (
    <>
      <Box className={styles.HeaderNav}>
        <ul>
          <li className={(itemIsActive('fun') || focusedSubNav==='fun')? styles.activeHeaderNavItem:styles.headerNavItem} style={{'--base-color':'var(--mui-palette-UltraPlush-cyan)'}} onMouseOver={()=>{setFocusedSubNav('fun')}} onFocus={()=>{setFocusedSubNav('fun')}} onMouseOut={()=>{setFocusedSubNav(0)}} onClick={triggerSubLink}>
            <Link to="/fun">
              <Typography variant="headerNav">Fun</Typography>
            </Link>
            <Box className={styles.SubNav}>
              <ul>
                <li style={{'--sub-base-color':'var(--mui-palette-UltraPlush-cyan)'}}>
                  <Link to="/fun/minigolf" >
                    <Typography variant="subNav">
                      Minigolf
                    </Typography>
                  </Link>
                </li>
                <li style={{'--sub-base-color':'var(--mui-palette-UltraPlush-magenta)'}}>
                  <Link to="/fun/mysteries">
                    <Typography variant="subNav">
                      Mysteries
                    </Typography>
                  </Link>
                </li>
                <li style={{'--sub-base-color':'var(--mui-palette-UltraPlush-cyan)'}}>
                  <Link to="/fun/immersive">
                    <Typography variant="subNav">
                      Immersive
                    </Typography>
                  </Link>
                </li>
                <li style={{'--sub-base-color':'var(--mui-palette-UltraPlush-magenta)'}}>
                  <Link to="/calendar">
                    <Typography variant="subNav">
                      Calendar
                    </Typography>
                  </Link>
                </li>
                <li style={{'--sub-base-color':'var(--mui-palette-UltraPlush-cyan)'}}>
                  <Link to="/fun/games">
                    <Typography variant="subNav">
                      Games
                    </Typography>
                  </Link>
                </li>
              </ul>
            </Box>
            
          </li>
          <li className={(itemIsActive('eats') || focusedSubNav==='eats')? styles.activeHeaderNavItem:styles.headerNavItem} style={{'--base-color':'var(--mui-palette-UltraPlush-magenta)'}} onMouseOver={()=>{setFocusedSubNav('eats')}} onFocus={()=>{setFocusedSubNav('eats')}} onMouseOut={()=>{setFocusedSubNav(0)}}>
            <Link to="/eats">
              <Typography variant="headerNav">Eats</Typography>
            </Link>
            <Box className={styles.SubNav}>
              <ul>
                <li style={{'--sub-base-color':'var(--mui-palette-UltraPlush-cyan)'}}>
                  <Link to="/eats/menu" >
                    <Typography variant="subNav">
                      Menu
                    </Typography>
                  </Link>
                </li>
                <li style={{'--sub-base-color':'var(--mui-palette-UltraPlush-magenta)'}}>
                  <Link to="/eats/delivery">
                    <Typography variant="subNav">
                      Delivery
                    </Typography>
                  </Link>
                </li>
                <li style={{'--sub-base-color':'var(--mui-palette-UltraPlush-cyan)'}}>
                  <Link to="/eats/dessert-bar">
                    <Typography variant="subNav">
                      Dessert Bar
                    </Typography>
                  </Link>
                </li>
                <li style={{'--sub-base-color':'var(--mui-palette-UltraPlush-magenta)'}}>
                  <Link to="/eats/beverage-walls">
                    <Typography variant="subNav">
                      Beverage Walls
                    </Typography>
                  </Link>
                </li>
              </ul>
            </Box>
            
            </li>
          <li><Link to="/" className={styles.HeaderMainLogo} onFocus={()=>{setFocusedSubNav('homeLink')}} ><img src={CMLogo512} /></Link></li>
          <li className={(itemIsActive('cart'))? styles.activeHeaderNavItem:styles.headerNavItem} style={{'--base-color':'var(--mui-palette-UltraPlush-cyan)'}}><Link to="/cart" onFocus={()=>{setFocusedSubNav('cart')}}><Typography variant="headerNav">Cart [0]</Typography></Link></li>
          {!!currentAuthenticatedUser && <li className={(itemIsActive('login'))? styles.activeHeaderNavItem:styles.headerNavItem} style={{'--base-color':'var(--mui-palette-UltraPlush-magenta)'}}><Link to={"/logout"} onFocus={()=>{setFocusedSubNav('login')}}><Typography variant="headerNav">{currentAuthenticatedUser.attributes.preferred_username}</Typography></Link></li>}
          {!currentAuthenticatedUser && <li className={(itemIsActive('login'))? styles.activeHeaderNavItem:styles.headerNavItem} style={{'--base-color':'var(--mui-palette-UltraPlush-magenta)'}}><Link to={"/login"} onFocus={()=>{setFocusedSubNav('login')}}><Typography variant="headerNav">Login</Typography></Link></li>}
        </ul>
      </Box>
    </>
  )
}

export default HeaderNav