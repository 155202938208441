import { Box, Typography } from '@mui/material';
import styles from './styles.module.scss';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import CMLogo512 from '../../assets/img/ObscuraCMLogo512.png';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import NavToggle from './navToggle';
import useGlobalState from '../../Stores/GlobalStateManager';


import useAuthState from '../../Stores/AuthStateManager';
import UserNavPad from './userNavPad';


const MobileNav=({})=>{
  const theme = useTheme();
  const isMobileViewport = useMediaQuery(theme.breakpoints.down('md'));
  const [navIsOpen,setNavIsOpen] = useState(false)
  const [activeNavItem,setActiveNavItem] = useState('');
  const {mobileNavIsOpen, setMobileNavIsOpen} = useGlobalState(state=>({mobileNavIsOpen:state.mobileNavIsOpen,setMobileNavIsOpen:state.setMobileNavIsOpen}))
  const {currentAuthenticatedUser} = useAuthState((state)=>({currentAuthenticatedUser:state.currentAuthenticatedUser}));
  const navTo = useNavigate();
  const location = useLocation();
  console.log('@@ currentAuthenticatedUser',currentAuthenticatedUser);
  const showNotifications = ()=>{alert(`Look I've shown you a notification`)} // TODO where should this go

  const confirmLogout = useCallback(()=>{ 
    if(location.pathname === '/logout'){
      setNavIsOpen(false);
    }
    else {
      const confirmation = window.confirm("Are you sure you want to logout?"); // TODO build a nice modal for this
      if (confirmation) {
        navTo('/logout');
      }
    }
  },[])

  const navToLogin = useCallback(()=>{
    if(location.pathname === '/login'){ 
      setNavIsOpen(false);
    }
    else {
      navTo('/login')
    }
  },[])

  const toggleNav = useCallback(()=>{
    setNavIsOpen(!navIsOpen)
  },[navIsOpen])
  const _toggleActiveNavItem = useCallback((name)=>{
    if (activeNavItem === name) {
      setActiveNavItem('')
    }
    else {
      setActiveNavItem(name)
    }
  },[activeNavItem,setActiveNavItem])

  useEffect(()=>{
    console.log('setting mobileNavIsOpen to',navIsOpen)
    setMobileNavIsOpen(navIsOpen)
  },[navIsOpen])

  if (!isMobileViewport) {
    return null;
  }
  
  return (
    <>
      <Box className={styles.MobileNav}>
        <Box className={styles.MobileNavHeader}>
          <span></span>
          <Link to="/" className={styles.headerLogo}>
            <img src={CMLogo512}  />
          </Link>
          <NavToggle onClick={toggleNav}/>
        </Box>
        <Box className={navIsOpen? styles.mobileNavBody : styles.mobileNavBodyClosed}>
          <ul>
            <li className={styles.mobileNavItem} style={{'--base-color':'var(--mui-palette-UltraPlush-magenta)'}} onClick={()=>{_toggleActiveNavItem('fun')}}>
              <Box className={activeNavItem === 'fun'? styles.mobileNavItemTopStripActive:styles.mobileNavItemTopStrip}>
                <Typography variant="mobileNavItem">Fun</Typography>
                <Box component="div"></Box>
                <Box component="div">
                  <Box component="div"></Box>
                  <Box component="div"></Box>
                </Box>
              </Box>
              <Box className={styles.mobileNavItemColorChip} >
                <Box className={styles.mobileNavItemChipHighlight}></Box>
              </Box>
              <Box>
                <ul className={activeNavItem === 'fun'? styles.mobileSubNavActive:styles.mobileSubNav}>
                  <li className={styles.mobileSubNavItem} style={{'--base-color':'var(--mui-palette-Obscura-JungleCitrine)'}}><Link to="/fun/minigolf"><Typography variant="mobileSubNavItem">Minigolf</Typography></Link></li>
                  <li className={styles.mobileSubNavItem} style={{'--base-color':'var(--mui-palette-Obscura-VerdantMystery)'}}><Link to="/fun/mysteries"><Typography variant="mobileSubNavItem">Mysteries</Typography></Link></li>
                  <li className={styles.mobileSubNavItem} style={{'--base-color':'var(--mui-palette-Obscura-SecretReef)'}}><Link to="/fun/immersive"><Typography variant="mobileSubNavItem">Immersive</Typography></Link></li>
                  <li className={styles.mobileSubNavItem} style={{'--base-color':'var(--mui-palette-Obscura-ParadisePapaya)'}}><Link to="/fun/calendar"><Typography variant="mobileSubNavItem">Calendar</Typography></Link></li>
                  <li className={styles.mobileSubNavItem} style={{'--base-color':'var(--mui-palette-Obscura-SapphireSkies)'}}><Link to="/fun/games"><Typography variant="mobileSubNavItem">Games</Typography></Link></li>
                </ul>
              </Box>
            </li>
            <li className={styles.mobileNavItem} style={{'--base-color':'var(--mui-palette-UltraPlush-cyan)'}} onClick={()=>{_toggleActiveNavItem('eats')}}>
              <Box className={activeNavItem === 'eats'? styles.mobileNavItemTopStripActive:styles.mobileNavItemTopStrip}>
                <Typography variant="mobileNavItem">Eats</Typography>
                <Box component="div"></Box>
                <Box component="div">
                  <Box component="div"></Box>
                  <Box component="div"></Box>
                </Box>
              </Box>
              <Box className={styles.mobileNavItemColorChip} >
                <Box className={styles.mobileNavItemChipHighlight}></Box>
              </Box>
              <Box>
                <ul className={activeNavItem === 'eats'? styles.mobileSubNavActive:styles.mobileSubNav}>
                  <li className={styles.mobileSubNavItem} style={{'--base-color':'var(--mui-palette-Obscura-ParadisePapaya)'}}><Link to="/eats/menu"><Typography variant="mobileSubNavItem">Menu</Typography></Link></li>
                  <li className={styles.mobileSubNavItem} style={{'--base-color':'var(--mui-palette-Obscura-RadiantStolenGold)'}}><Link to="/eats/delivery"><Typography variant="mobileSubNavItem">Delivery</Typography></Link></li>
                  <li className={styles.mobileSubNavItem} style={{'--base-color':'var(--mui-palette-Obscura-SecretReef)'}}><Link to="/eats/dessert-bar"><Typography variant="mobileSubNavItem">Dessert Bar</Typography></Link></li>
                  <li className={styles.mobileSubNavItem} style={{'--base-color':'var(--mui-palette-Obscura-VerdantMystery)'}}><Link to="/eats/beverage-walls"><Typography variant="mobileSubNavItem">Beverage Walls</Typography></Link></li>
                </ul>
              </Box>
            </li>
            <li className={styles.mobileNavItem} style={{'--base-color':'var(--mui-palette-UltraPlush-magenta)'}} onClick={()=>{_toggleActiveNavItem('cart')}}>
              <Box className={activeNavItem === 'cart'? styles.mobileNavItemTopStripActive:styles.mobileNavItemTopStrip}>
                <Typography variant="mobileNavItem">Cart[0]</Typography>
                <Box component="div"></Box>
                <Box component="div">
                  <Box component="div"></Box>
                  <Box component="div"></Box>
                </Box>
              </Box>
              <Box className={styles.mobileNavItemColorChip} >
                <Box className={styles.mobileNavItemChipHighlight}></Box>
              </Box>
            </li>
            {!currentAuthenticatedUser && <li className={styles.mobileNavItem} style={{'--base-color':'var(--mui-palette-UltraPlush-cyan)'}} onClick={navToLogin}>
              <Box className={activeNavItem === 'login'? styles.mobileNavItemTopStripActive:styles.mobileNavItemTopStrip}>
                <Typography variant="mobileNavItem">Login</Typography>
                {/* glow */}
                <Box component="div"></Box>
                <Box component="div">
                  <Box component="div"></Box>
                  <Box component="div"></Box>
                </Box>
              </Box>
              <Box className={styles.mobileNavItemColorChip} >
                <Box className={styles.mobileNavItemChipHighlight}></Box>
              </Box>
            </li>}
            {!!currentAuthenticatedUser && <li className={styles.mobileNavItem} style={{'--base-color':'var(--mui-palette-UltraPlush-cyan)'}} >
              <Box className={activeNavItem === 'user'? styles.mobileNavItemTopStripActive:styles.mobileNavItemTopStrip}>
                <Typography variant="mobileNavItem" style={{display:'flex', alignItems:'center'}}>
                  <div style={{width:'50px',height:'50px',borderRadius:'100px',background:'#000',marginRight:'25px'}}/>{/* TODO add user profile pic */}{currentAuthenticatedUser?.attributes?.preferred_username}</Typography>
                {/* glow */}
                <Box component="div"></Box>
                <Box component="div">
                  <Box component="div"></Box>
                  <Box component="div"></Box>
                </Box>
              </Box>
              <Box className={styles.mobileNavItemColorChip} >
                <Box className={styles.mobileNavItemChipHighlight}></Box>
              </Box>
            </li>}
            {!!currentAuthenticatedUser && <li className={styles.userNavPad}>      
              <UserNavPad showNotifications={showNotifications} confirmLogout={confirmLogout}/>
            </li>}
          </ul>
        </Box>
      </Box>
    </>
  )
}

export default MobileNav