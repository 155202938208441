import {create} from 'zustand';
import { subscribeWithSelector } from 'zustand/middleware'
import { API } from 'aws-amplify';
import {getWhiteRabbit} from '../../graphql/queries'
import {foundRabbit} from '../../graphql/mutations'
import { Auth } from 'aws-amplify';
import FoundRabbitGoodyTemplate from '../../Components/GoodyModal/templates/foundRabbit';
const mockRabbitListing = [
  {
    name: 'Consuela',
    thumb512: 'tbd',
    description: 'Consuela description here'
  },
  {
    name: 'Addie',
    thumb512: 'tbd',
    description: 'Addie description here'
  }
]

const mockGetWhiteRabbit = (rabbitId)=>{
  const _rabbit = mockRabbitListing.find((rabbitListing)=>rabbitListing.name===rabbitId);
  return _rabbit || null;
}

const useGlobalState = create(subscribeWithSelector((set,get)=>({
  mobileNavIsOpen: false,
  setMobileNavIsOpen: (newNavState)=>{
    set({mobileNavIsOpen:newNavState})
  },
  showTeaserVid: true,
  setShowTeaserVid: (newShowState)=>{
    set({showTeaserVid:newShowState})
  },
  showAccountNag: ()=>{},
  createUserCollections: async (rabbitId)=>{
    // create local storage
    localStorage.setItem("rabbits",JSON.stringify([rabbitId]));
    localStorage.setItem("inventory",JSON.stringify([]));
    localStorage.setItem("achievements",JSON.stringify([]));
    // if user is authed call createUserCollectionsRemote
    let user;
    try {
      user = await Auth.currentAuthenticatedUser();
    }
    catch(err){
      //shh
    }
    
    if (!!user) {
      get().createUserCollectionsRemote(rabbitId);
    }
    // add note to goodyModal stack
  },
  createUserCollectionsRemote:()=>{
    // stubbed pending AWS resolution
  },
  updateUserCollections: async (updates)=>{
    const doSectionUpdate = (key)=>{
      if (!!updates[key] && !!Object.keys(updates[key]).length) {
        let _section = JSON.parse(localStorage.getItem(key));
        // removals
        if (!!updates[key].remove && !!Object.keys(updates[key]?.remove)?.length) {
          updates[key].remove.map((itemToRemove)=>{
            let _idx = _section.indexOf(itemToRemove);
            if (_idx !== -1) {
              _section.splice(_idx,1);
            }
          })
        }
        // additions
        if (!!updates[key].add && !!Object.keys(updates[key]?.add)?.length) {
          updates[key].add.map((itemToAdd)=>{
            _section.push(itemToAdd);
          })
        }
        // write to local
        localStorage.setItem(key,JSON.stringify(_section))
      }
    }
    doSectionUpdate("rabbits");
    doSectionUpdate("inventory");
    doSectionUpdate("achievements");

    /*
    if (updates?.rabbits) {
      let _rabbits = JSON.parse(localStorage.getItem("rabbits"));
      // removals
      if (updates.rabbits.remove?.length) {
        updates.rabbits.remove.map((rabbitToRemove)=>{
          let _idx = _rabbits.indexOf(rabbitToRemove);
          if (_idx !== -1) {
            _rabbits.splice(_idx,1);
          }
        })
      }
      // additions
      if (updates.rabbits.add?.length) {
        updates.rabbits.add.map((rabbitToAdd)=>{
          _rabbits.push(rabbitToAdd);
        })
      }
      // write to local
      localStorage.setItem("rabbits",JSON.stringify(_rabbits))
    }
    */
    // if user is authed call createUserCollectionsRemote
    let user;
    try{
      user = await Auth.currentAuthenticatedUser();
    }
    catch(err){
      //shh
    }
    if (!!user) {
      get().updateUserCollectionsRemote(updates);
    }
  },
  updateUserCollectionsRemote:()=>{
    // stubbed pending aws resolution

    // old version looked a bit like this
    /*
     Auth.currentAuthenticatedUser()
        .then((user)=>{ // if logged in
          // add to dbase
          // graphql query to findRabbit lambda which will validate and add
          console.log('user',user)
          const idToken = user.signInUserSession.idToken.jwtToken
          console.log('idToken',idToken)
          API.graphql({
            query:foundRabbit,
            variables:{userId:user.attributes.sub,rabbitId:"console", authToken:idToken},
            headers: {
              Authorization: `Bearer ${idToken}`
            }
          })
          // updateUserCollections after update returns to refresh local stores
        }) 
        .catch((user)=>{ // if not logged in
          // add to localStorage
          // every third find, nag to signup if not signed in
        }) 
    */
  },
  rabbitCollection: ()=>{
    const rabbits = JSON.parse(localStorage.getItem("rabbits"));
    return rabbits;
  },
  findRabbit: async(rabbitId)=>{
    // isRabbitValid graphQL Query goes here, pending AWS resolution
      // passes rabbitId to graphql query isRabbitValid lambda resolver which will return rabbit details or false
      // const rabbitDetails = await API.graphql({query:getWhiteRabbit,variables:{id:rabbitId}})
      // console.log('rabbitDetails',rabbitDetails)
      // if false, invalid rabbit, return null
      // if (!rabbitDetails) {
      //   return null
      // }
    // TEMP MOCK PENDING AWS RESOLUTION
    const rabbit = mockGetWhiteRabbit(rabbitId);
    if (!rabbit || rabbit === null) { // invalid rabbit, naughty naughty, bail
      return null;
    }
    // rabbit is valid, check local storage to see how many rabbits already found and if this rabbit is among them (no db check)
    const userHeldRabbits = get().rabbitCollection();
    console.log('userHeldRabbits',userHeldRabbits)
    if (!userHeldRabbits?.length) { // first rabbit
      //simultaneously pop the found rabbit modal and create local stores
      get().addToGoodyModals(<FoundRabbitGoodyTemplate name={rabbit.name} description={rabbit.description} advance={get().shiftGoodyModals} />)
      get().createUserCollections(rabbitId);
    }
    else if (userHeldRabbits?.indexOf(rabbitId) === -1) { // if not in userHeldRabbits collection, add
      //simultaneously pop the found rabbit modal and update stores
      get().addToGoodyModals(<FoundRabbitGoodyTemplate name={rabbit.name} description={rabbit.description} advance={get().shiftGoodyModals}/>)
      const updates = {
        rabbits: {
          add: [rabbitId]
        }
      }
      get().updateUserCollections(updates);
      // determine correct action to take based on num rabbits already found in localStorage
      if (userHeldRabbits.length + 1 === 5) {
        // if fifth, add and show induction token
        // add induction token to goodyModal stack
      }
      // add achievement event for finding all rabbits when final count is established
      let user;
      try{
        // debugger;
        user = await Auth.currentAuthenticatedUser();
      }
      catch(err){
        // shh
      }
      if (!user) {
        if ((userHeldRabbits.length + 1) % 3 === 0) { // every third rabbit, nag to create an account
          get().showAccountNag();
        } 
      }
    }
    else { // already found do nothing
      return null;
    }   
  },
  goodyModals: [],
  setGoodyModals: (newModals)=>{set({goodyModals:newModals})},
  addToGoodyModals: (itemsToAdd)=>{
    const _goodies = get().goodyModals;
    if (Array.isArray(itemsToAdd)){
      _goodies.push(...itemsToAdd);
    }
    else {
      _goodies.push(itemsToAdd);
    }
    set({goodyModals:_goodies})
  },
  removeFromGoodyModalsByIdx: (index)=>{
    const _goodies = get().goodyModals;
    _goodies.splice(index,1);
    set({goodyModals:_goodies})
  },
  shiftGoodyModals: ()=>{
    const _goodies = get().goodyModals;
    _goodies.pop();
    get().setGoodyModals(_goodies);
    set({goodyModals:_goodies})
  }
})))

// TODO DELME TEST 
// const state = useGlobalState.getState();
// state.addToGoodyModals(<FoundRabbitGoodyTemplate description='Cupcake ipsum dolor sit amet chupa chups. Cake candy canes macaroon jelly beans lollipop. Caramels cookie oat cake cotton candy cookie gingerbread gummi bears. Chupa chups cookie sweet candy fruitcake lollipop cotton candy.' advance={state.shiftGoodyModals} />)


export default useGlobalState