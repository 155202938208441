import { Box, Grid, Typography } from '@mui/material';
import styles from './styles.module.scss';
import LogoStrip from '../../Components/LogoStrip';
import Panel from './panel';
import { useNavigate } from 'react-router-dom';
import {ReactComponent as FlyThroughIcon} from '../../assets/img/noun-jet-1182290.svg'
import {ReactComponent as FinanceIcon} from '../../assets/img/noun-sales-pitch-1014774.svg'
const InvestorKit=({})=>{
  const navTo = useNavigate();
  return (
    <>
      <LogoStrip  sx={{marginTop:'40px'}}/>
      <Box className={styles.header}>
        <Typography variant='investor_kit'>Investor Kit</Typography>
      </Box>
      <Box className={styles.InvestorKit}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={4}>
            <Panel title="Pitch" onClick={()=>{navTo("/investor/pitch")}} shadowColor={"rgba(0,65,90,0.74)"}/>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Panel title="Flythru" Icon={FlyThroughIcon} iconScale={2} onClick={()=>{navTo("/investor/flythrough")}} color={'var(--mui-palette-Obscura-JungleCitrine)'} shadowColor={"rgba(128,78,22,0.56)"}/>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Panel title="Numbers" Icon={FinanceIcon} onClick={()=>{navTo("/investor/finances")}} color={'var(--mui-palette-UltraPlush-magenta)'} shadowColor={"rgba(113,0,67,0.74)"}/>
            </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default InvestorKit