import styles from './styles.module.scss';
import {ReactComponent as OpenButton} from '../../assets/img/noun-menu-101353.svg';
import {ReactComponent as CloseButton} from '../../assets/img/noun-close-1035670.svg';
const NavToggle=({onClick})=>{
  return (
    <>
      <OpenButton className={styles.openButton} onClick={onClick}/>
    </>
  )
}

export default NavToggle