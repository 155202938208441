import { Box, Button, TextField, Typography } from '@mui/material';
import BasePageTemplate from '../BasePageTemplate';
import styles from './styles.module.scss';
import { useCallback, useMemo, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { MuiTelInput } from 'mui-tel-input';
import useAuthState from '../../Stores/AuthStateManager';
import { Auth } from 'aws-amplify';
import GreebleModal from '../../Components/GreebleModal';
import TeaserVid from '../../Components/TeaserVid';

const LoginPage=({})=>{
  const [phoneNo,setPhoneNo] = useState('');
  const {doLogin} = useAuthState((state)=>({doLogin:state.doLogin}))
  const navTo = useNavigate();

  const onPhoneNoChange = useCallback((newVal)=>{
    console.log('setting phone no to ', newVal)
    setPhoneNo(newVal);
  },[])

  const _doLogin = useCallback(async ()=>{
    const loginResult = await doLogin(phoneNo.replace(/\s/g, ''));
    if (loginResult) {
      navTo('/verifyAuth');
    }
    
  },[phoneNo])
  
  const InputFields = useMemo(()=>{
    return <Box className={styles.fieldsWrapper}>
      <MuiTelInput name="phonenumber" defaultCountry='US' label="Phone Number" value={phoneNo} onChange={onPhoneNoChange} />
    </Box>
  },[phoneNo,onPhoneNoChange]) 

  return (
    <>
      <BasePageTemplate>
        <GreebleModal header="Login to Obscura" mainBodyContent={InputFields} secondaryBodyContent="I understand that by logging in to Obscura, I consent to receive One Time Passwords (OTPs) at this number. Messaging and data rates may apply." actionButtonTitles={["Log In"]} actionButtonActions={[_doLogin]} postActionButtonContent={<p>Need an account? <br/> <a onClick={()=>{navTo('/signup')}}>Sign up here!</a></p>}/>
        {/* <TeaserVid /> */}
      </BasePageTemplate>
    </>
  )
}

export default LoginPage